



/** *************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js';
  // Included with Angular CLI.
(window as any).process = {
  env: { DEBUG: undefined },
};



/** *************************************************************************************************
 * APPLICATION IMPORTS
 */
